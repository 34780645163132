import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import './styles.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Loading from '../../components/Loading/Loading';

import PeriodContext from '../../Contexts/PeriodContext';
import apiTuig from '../../services/apiTuig';

import { UltimaAtualizacao, VendasGeral, AcessConfig, DetalheFormaPag } from '../../Types/Types';
import LoginContext from '../../Contexts/LoginContext';

import { useHistory } from 'react-router-dom';

const Vendas = () => {

        const Period = useContext(PeriodContext);
        const Login = useContext(LoginContext);

        const history = useHistory();

        const [carregandoVendas,setCarregandoVendas] = useState(true);
        const [ultimaAtualizacao, setUltimaAtualizacao] = useState<UltimaAtualizacao>({
            ultAtualizacao: '',
            regPendentes: '',
            error: ''
        });
        const [vendas, setVendas] = useState<VendasGeral>({
            total: '',
            qtd: '',
            ticketMedio: '',
            cancelamentos: '',
            descontos: '',
            totalComparacao: '',
            error: ''
        });
        const [detalheFormaPag, setDetalheFormaPag] = useState<DetalheFormaPag>({
            soma: '',
            dinheiro: '',
            ordPag: '',
            depBanc: '',
            cheque: '',
            cartoes: '',
            credi: '',
            error: ''
        });

        useEffect(() => {
            LoadHome();       
        },[]); 

        async function CarregarUltAtualizacao() {
            try {
                if (Login.user != null) {
                    await apiTuig.get(`getUltAtualizacao?acessKey=${Login.client}&user=${Login.user}&pass=${Login.pass}`).then(response => {
                        setUltimaAtualizacao(response.data);
                        //Se senha ou autorizacao mudar no servidor, desloga usuario
                        if (response.data.error !== '') {
                            alert(response.data.error);
                            return (
                                <div>
                                    {GoToLogin()};
                                </div>
                            )
                        }
                        })
                }
            } catch (err) {
                alert(`Erro ao carregar hora da última atualização, verifique a conexão com a internet e tente novamente. -> ${String(err)}`);
            }
        }

        async function CarregarVendas() {
            try {
                if (Login.user != null) {
                    setCarregandoVendas(true);
                    await apiTuig.get(`vendascompleto?acessKey=${Login.client}&user=${Login.user}&pass=${Login.pass}&data=${Period.dayFrom.toLocaleDateString('pt-BR', { timeZone: 'America/Recife' })}`).then(response => {
                        setVendas(response.data);
                        setCarregandoVendas(false);
                        //Se senha ou autorizacao mudar no servidor, desloga usuario
                        if (response.data.error !== '') {
                            alert(response.data.error);
                            return (
                                <div>
                                    {GoToLogin()};
                                </div>
                            )
                        }
                    })
                }
            } catch (err) {
                setCarregandoVendas(false);
                alert('Erro ao carregar vendas, verifique a conexão com a internet e tente novamente.');
            }
        }

        async function CarregarDetalhesFormaPag() {
            try {
                if (Login.user != null) {
                    await apiTuig.get(`vendasDetFormaPag?acessKey=${Login.client}&user=${Login.user}&pass=${Login.pass}&data=${Period.dayFrom.toLocaleDateString('pt-BR', { timeZone: 'America/Recife' })}`).then(response => {
                        setDetalheFormaPag(response.data);
                        //Se senha ou autorizacao mudar no servidor, desloga usuario
                        if (response.data.error !== '') {
                            alert(response.data.error);
                            return (
                                <div>
                                    {GoToLogin()};
                                </div>
                            )
                        }
                    })
                }
            } catch (err) {
                alert('Erro ao carregar detalhes de de formas de pagamento, verifique a conexão com a internet e tente novamente.');                
            }
        }

        useEffect(() => {
            CarregarUltAtualizacao();
            CarregarVendas();  
            CarregarDetalhesFormaPag();         
        },[]);

        function GoToLogin() {
            history.push('/');
        }
    
        function LoadHome() {
            if (sessionStorage.getItem('@tuigcloud/dayFrom') != null) {
                const momentObj = moment(sessionStorage.getItem('@tuigcloud/dayFrom'), 'DD/MM/YYYY');
                Period.dayFrom = momentObj.toDate();
            }
            Login.isAutenticated = sessionStorage.getItem('@tuigcloud/isAutenticated');
            Login.user = sessionStorage.getItem('@tuigcloud/user');
            Login.pass = sessionStorage.getItem('@tuigcloud/pass');
            Login.client = localStorage.getItem('@tuigcloud/client');
            Login.clientName = sessionStorage.getItem('@tuigcloud/clientName');
            
            if (Login.isAutenticated === 'true') {
                return (
                        <div id="home">
                    <div className="content">
                        <Header />
                        <br/>
                        <br/>
                        <p>Atualizado em {ultimaAtualizacao.ultAtualizacao}</p>
                        <p>{ultimaAtualizacao.regPendentes} registros a sincronizar</p>
                        <br/>
                        <h3>{Login.clientName}</h3>
                        <h1>Vendas do dia {Period.dayFrom.toLocaleDateString('pt-BR', { timeZone: 'America/Recife' })}</h1>
                        
                        {carregandoVendas ? <div></div> : <div>
                                                          <h4>{vendas.total}</h4>
                                                          <h4>{vendas.qtd} vendas</h4>    
                                                          <h6>{vendas.totalComparacao} em comparativo com o dia anterior</h6>
                                                          <br/>
                                                          <h6>Ticket Médio: {vendas.ticketMedio}</h6>    
                                                          </div>}
                        <div className="Detalhes">
                            {carregandoVendas ? <div></div> : <div>
                                                              <p>Cancelamentos: {vendas.cancelamentos}</p>
                                                              <p>Descontos: {vendas.descontos}</p>
                                                              </div>
                                                              }
                            <br/>
                            <hr/>
                            <h4>Detalhamento por forma de pagamento</h4>
                            {carregandoVendas ? <Loading /> : <div>
                                                            <p>Dinheiro: {detalheFormaPag.dinheiro}</p>
                                                            <p>Cartões: {detalheFormaPag.cartoes}</p>
                                                            <p>Cheque: {detalheFormaPag.cheque}</p>
                                                            <p>A prazo: {detalheFormaPag.credi}</p>
                                                            <p>Dep. Bancário: {detalheFormaPag.depBanc}</p>
                                                            <p>Ord. Pagamento: {detalheFormaPag.ordPag}</p>
                                                            </div>
                                                            }
                            <hr/>
                            <Footer />
                        </div>
                        
                    </div>
                </div>
                )
            }
            else {
                return (
                    <div>
                        {GoToLogin()};
                    </div>
                )
            }
        }
    return (
        <div>{LoadHome()}</div>
    );
};

export default Vendas;