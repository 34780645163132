import { createContext, useState } from 'react';

interface Period { //Criando o tipo do contexto
    dayFrom: Date;
};

const PeriodContext = createContext<Period>({
    dayFrom: new Date() //Inicializar o context com dataAtual
});

export default PeriodContext;