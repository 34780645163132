import React, { ChangeEvent, FormEvent, useState, useContext, useEffect } from 'react';

import './styles.css';
import moment from 'moment';

import { useHistory }  from 'react-router-dom';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import LoginContext from '../../Contexts/LoginContext';

import { AcessConfig, RedeEmpresas } from '../../Types/Types';
import Loading from '../../components/Loading/Loading';
import apiTuig from '../../services/apiTuig';

// Local Storage - Grava ate que o usuario esvazie o cache
// localStorage.setItem('@nome-do-app/chave', valor);
// localStorage.getItem('@nome-do-app/chave');
// localStorage.removeItem('@nome-do-app/chave');
// Session Storage - Grava ate que o usuario feche o navegador
// sessionStorage.setItem('@nome-do-app/chave', valor);
// sessionStorage.getItem('@nome-do-app/chave');
// sessionStorage.removeItem('@nome-do-app/chave');
    
const Login = () => {

    const history = useHistory();
    const Login = useContext(LoginContext);

    const [redeEmpresas,setRedeEmpresas] = useState<RedeEmpresas[]>([{
        idEmpresa: '',
        codEmpresa: '',
        nomeFantasia: '',
        error: ''
    }]);
    const [carregando,setCarregando] = useState(false);
    const [client,setClient] = useState('');
    const [user,setUser] = useState('');
    const [pass,setPass] = useState('');
    
    const [acessConfig, setAcessConfig] = useState<AcessConfig>({
        idEmpresa: '',
        codEmpresa: '',
        user: '',
        pass: '',
        nomeFantasia: '',
        path: '',
        port: '',
        ultAtualizacao: '',
        regPendentes: '',
        error: ''
    });

    function handleChangeClient(event: ChangeEvent<HTMLInputElement>){
        setClient(event.target.value);
        Login.client = event.target.value;        
    };

    function handleChangeClientMenu(event: ChangeEvent<HTMLSelectElement>){
        setClient(event.target.value);
        Login.client = event.target.value;        
    };

    async function CarregarRedeEmpresas() {
        try {
            if (Login.user != null) {
                await apiTuig.get(`getRedeEmpresas?acessKey=${localStorage.getItem('@tuigcloud/client')}`).then(response => {
                    setRedeEmpresas(response.data);
                    console.log(response.data)
                    })
            }
        } catch (err) {
            //alert(`Erro ao carregar hora da última atualização, verifique a conexão com a internet e tente novamente. -> ${String(err)}`);
        }
    }

    useEffect(() => {
        LoadHome();  
        const clientLocalStorage = localStorage.getItem('@tuigcloud/client');
        if (clientLocalStorage) {
            setClient(clientLocalStorage);
            CarregarRedeEmpresas();
        }        
    },[]); 

    function handleChangeUser(event: ChangeEvent<HTMLInputElement>){
        setUser(event.target.value);
        Login.user = event.target.value;
    };

    function handleChangePass(event: ChangeEvent<HTMLInputElement>){
        setPass(event.target.value.replace("#","%23")); //Trocar # da senha por %23 para passar na URL    
        Login.pass = event.target.value;
    };

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();

        if (client !== '' && Login.user !== '' && pass !== '') {
            //Modo que jorge faz
            try {
                setCarregando(true);
                const res = await apiTuig.get<AcessConfig>(`getEmpresa?acessKey=${client.toUpperCase()}&user=${user.toUpperCase()}&pass=${pass.toUpperCase()}`); 
               //     const teste = res.data;
            
                    setAcessConfig(res.data);
                    setCarregando(false);
                //     console.log(acessConfig);
                //     console.log(res.data);
                //     alert(res.data.Erro);
                
                // await apiCore.get<AcessConfig>(`getEmpresa?acessKey=${client.toUpperCase()}`).then(response => {
                //     const acessConfig1 = response.data;
                //     setAcessConfig(response.data);
                //     console.log(response.data);
                //     console.log(acessConfig1);
                //     alert(response.data.Erro);
                // })
            
                if (res.data.error === '') {
                    sessionStorage.setItem('@tuigcloud/isAutenticated', 'true');
                    sessionStorage.setItem('@tuigcloud/user', res.data.user);
                    sessionStorage.setItem('@tuigcloud/pass', res.data.pass);
                    localStorage.setItem('@tuigcloud/client', res.data.codEmpresa);
                    sessionStorage.setItem('@tuigcloud/clientName', res.data.nomeFantasia);
                    
                    Login.isAutenticated = 'true';
                    Login.user = res.data.user;
                    Login.pass = res.data.pass;
                    Login.client = res.data.codEmpresa;
                    Login.clientName = res.data.nomeFantasia;
                    
                    history.push('/dashboard');
                } 
                else {
                    if (res.data.error !== '') {
                        alert(res.data.error);
                    }
                    else {
                        alert('Dados de acesso inválidos!');
                    }
                }
            } catch (err) {
                setCarregando(false);
                alert(`Erro ao efetuar login, verifique a conexão com a internet e tente novamente. ${String(err)}`);
            }
        }
        else {
            alert('Preencha todos os campos!');
        }
    };

    function LoadHome() {
        sessionStorage.removeItem('@tuigcloud/isAutenticated');
        sessionStorage.removeItem('@tuigcloud/user');
        sessionStorage.removeItem('@tuigcloud/pass');
        //localStorage.removeItem('@tuigcloud/client');
        sessionStorage.removeItem('@tuigcloud/clientName');
    }
    
    return (        
        <div id="Login">          
            <Header />
            <form onSubmit={handleSubmit}>                
                <div id="formLogin">
                    <h1>Por favor, efetue o login</h1>
                    {redeEmpresas[0].codEmpresa ? <div>
                                                    <div>
                                                        <label htmlFor="">Selecione a empresa ou digite a chave de acesso</label>
                                                    </div>
                                                    <div>
                                                        <select name="selectClient" id="selectClient" onChange={handleChangeClientMenu} defaultValue={String(localStorage.getItem('@tuigcloud/client'))}>
                                                            {redeEmpresas.map(empresa => ( 
                                                                <option key={empresa.codEmpresa} value={empresa.codEmpresa}>{empresa.nomeFantasia}</option>
                                                            ))}
                                                        </select>
                                                    </div> 
                                                  </div>
                                                : <div></div>                                     
                    }
                    <br/>
                    <div>
                        <label htmlFor="accessKey">Chave de acesso da empresa</label>
                    </div>
                    <div>
                        <input className="inputLogin" type="text" name="idclient" value={client} onChange={handleChangeClient} />
                    </div>
                    <div>
                        <label htmlFor="user">Usuário</label>
                    </div>
                    <div>
                        <input className="inputLogin" type="text" name="user" onChange={handleChangeUser}/>
                    </div>
                    <div>
                        <label htmlFor="pass">Senha</label>
                    </div>
                    <div>
                        <input className="inputLogin" type="password" name="pass" onChange={handleChangePass}/>
                    </div>
                    <div>
                        {carregando ? <Loading /> : <button id="botaoAcessar" type="submit">Acessar</button> }
                        {/*//<Link to="/dashboard"><strong>Login</strong></Link>*/}
                    </div>    
                    <div id="rodape"><Footer /></div>                       
                </div>
                
            </form>
                     
        </div>        
    )
} 

export default Login;