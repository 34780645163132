import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const VendasLoading = () => {
    return (
        <div>
            <Spinner animation="border" />
        </div>
    )
}

export default VendasLoading;
