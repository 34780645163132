import React, { createContext } from 'react';

interface Login {
    isAutenticated: string | null,
    user?: string | null,
    pass?: string | null,
    client?: string | null,
    clientName?: string | null,
}

const LoginContext = createContext<Login>({
    isAutenticated: 'false',
    user: '',
    pass: '',
    client: '',
    clientName: '',
});

export default LoginContext;