import React from 'react';

import logoInove from '../../assets/logoInove.svg';
import './styles.css';

const Footer = () => {
    return (        
        <div id="rodape">
            <p>Suporte Técnico</p>
            <p id="fones">(87) 3831-1594 (Fixo e WhatsApp)</p>
            <p>(87) 99965-6771 (Tim) / 98843-9523 (Oi)</p>
            <p>Seg. a Sex. das 08:00h às 18:00h e Sáb. das 08:00h às 12:00h</p>
            <br/>
            <a href="https://loja.inovesistemas.info"><img id="logoInove" src={logoInove} alt=""/></a>
        </div>
    )
} 

export default Footer;