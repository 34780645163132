import React from 'react';

import logoTuig from '../../assets/tuigCloud.png';
import './styles.css';

const Header = () => {
    return (
        <header>
            <a href="https://tuig.inovesistemas.info"><img id="logoTuig" src={logoTuig} alt="Tuig Cloud"/></a>                    
        </header>    
    )
}

export default Header;