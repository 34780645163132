import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Vendas from './pages/Vendas';
import ProdutosVendidos from './pages/ProdutosVendidos';
import VendasVendedor from './pages/VendasVendedor';
import SaldoCaixa from './pages/SaldoCaixa';
import RetiradasCaixa from './pages/RetiradasCaixa';

const Routes = () => {
    return (
        <BrowserRouter>
            <Route component={Login} path="/" exact/> 
            <Route component={Dashboard} path="/dashboard"/>
            <Route component={Login} path="/login"/>
            <Route component={Vendas} path="/vendas"/>
            <Route component={ProdutosVendidos} path="/produtosvendidos"/>
            <Route component={VendasVendedor} path="/vendasvendedor"/>
            <Route component={SaldoCaixa} path="/saldocaixa"/>
            <Route component={RetiradasCaixa} path="/retiradascaixa"/>
        </BrowserRouter>
    );
}

export default Routes;