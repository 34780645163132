import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import './styles.css';

import { Link, useHistory } from 'react-router-dom';

import apiTuig from '../../services/apiTuig';
import bannerBonif from '../../assets/bannerBonif.png';
import bannerChanges from '../../assets/bannerChanges.png';
import bannerLoja from '../../assets/bannerLoja.png';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Loading from '../../components/Loading/Loading';
import Button from '../../components/Button/Button';

import PeriodContext from '../../Contexts/PeriodContext';

import { UltimaAtualizacao, VendasGeral, SaldoCaixa, RetiradasCaixa } from '../../Types/Types';
import LoginContext from '../../Contexts/LoginContext';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from 'date-fns/locale';
import { registerLocale } from  "react-datepicker";
import { link } from 'fs';
registerLocale('ptBR', ptBR)

interface TopProdutosResponse {
    produto: string,
    error: string;
}

interface TopVendedoresResponse {
    vendedor: string,
    error: string;
}

const Dashboard = () => {
   
    const Period = useContext(PeriodContext);
    const Login = useContext(LoginContext);

    const history = useHistory();

    const [carregandoVendas,setCarregandoVendas] = useState(true);
    const [carregandoTopProdutos,setCarregandoTopProdutos] = useState(true);
    const [carregandoTopVendedores,setCarregandoTopVendedores] = useState(true);
    const [carregandoSaldoCaixa,setCarregandoSaldoCaixa] = useState(true);
    const [carregandoRetiradasCaixa,setCarregandoRetiradasCaixa] = useState(true);
    
    const [startDate, setStartDate] = useState(sessionStorage.getItem('@tuigcloud/dayFrom') ? sessionStorage.getItem('@tuigcloud/dayFrom') : new Date().toLocaleDateString('pt-BR', { timeZone: 'America/Recife' }));
    const [ultimaAtualizacao, setUltimaAtualizacao] = useState<UltimaAtualizacao>({
        ultAtualizacao: '',
        regPendentes: '',
        error: ''
    });
    const [vendas, setVendas] = useState<VendasGeral>({
        total: '',
        qtd: '',
        ticketMedio: '',
        cancelamentos: '',
        descontos: '',
        totalComparacao: '',
        error: ''
    });
    const [saldoCaixa,setSaldoCaixa] = useState<SaldoCaixa>({
        valor: '',
        error: ''
    })
    const [retiradasCaixa,setRetiradasCaixa] = useState<RetiradasCaixa>({
        retiradas: '',
        valor: '',
        error: ''
    })
    
    const [topProdutos, setTopProdutos] = useState<string[]>([]);

    const [topVendedores, setTopVendedores] = useState<string[]>([]);

    async function CarregarUltAtualizacao() {
        try {
            if (Login.user != null) {
                await apiTuig.get(`getUltAtualizacao?acessKey=${Login.client}&user=${Login.user}&pass=${Login.pass}`).then(response => {
                    setUltimaAtualizacao(response.data);
                    //Se senha ou autorizacao mudar no servidor, desloga usuario
                    if (response.data.error !== '') {
                        alert(response.data.error);
                        return (
                            <div>
                                {GoToLogin()};
                            </div>
                        )
                    }
                    })
                }
        } catch (err) {
            alert(`Erro ao carregar hora da última atualização, verifique a conexão com a internet e tente novamente. -> ${String(err)}`);
        }
    }

    async function CarregarVendas() {
        try {
            if (Login.user != null) {
                setCarregandoVendas(true);
                await apiTuig.get(`vendasresumo?acessKey=${Login.client}&user=${Login.user}&pass=${Login.pass}&data=${Period.dayFrom.toLocaleDateString('pt-BR', { timeZone: 'America/Recife' })}`).then(response => {
                    setVendas(response.data);
                    setCarregandoVendas(false);
                    //Se senha ou autorizacao mudar no servidor, desloga usuario
                    if (response.data.error !== '') {
                        alert(response.data.error);
                        return (
                            <div>
                                {GoToLogin()};
                            </div>
                        )
                    }
                    })
                }
        } catch (err) {
            setCarregandoVendas(false);
            alert(`Erro ao carregar vendas, verifique a conexão com a internet e tente novamente. -> ${String(err)}`);
        }
    }

    async function CarregarTopProdutos() {
        try {
            if (Login.user != null) {
                setCarregandoTopProdutos(true);
                await apiTuig.get<TopProdutosResponse[]>(`topprodutos?acessKey=${Login.client}&user=${Login.user}&pass=${Login.pass}&data=${Period.dayFrom.toLocaleDateString('pt-BR', { timeZone: 'America/Recife' })}&qtdReg=3`).then(response => {
                    const produto = response.data.map(produto => produto.produto);
                    setTopProdutos(produto);
                    setCarregandoTopProdutos(false);   
                })
            }
        } catch (err) { 
            setCarregandoTopProdutos(false);
            alert(`Erro ao carregar Top 3 produtos mais vendidos, verifique a conexão com a internet e tente novamente. -> ${String(err)}`);
        }
    }

    async function CarregarTopVendedores() {
        try {
            if (Login.user != null) {
                setCarregandoTopVendedores(true);
                await apiTuig.get<TopVendedoresResponse[]>(`topVendedores?acessKey=${Login.client}&user=${Login.user}&pass=${Login.pass}&data=${Period.dayFrom.toLocaleDateString('pt-BR', { timeZone: 'America/Recife' })}&qtdReg=3`).then(response => {
                    const vendedor = response.data.map(vendedor => vendedor.vendedor);
                    setTopVendedores(vendedor);
                    setCarregandoTopVendedores(false);   
                })
            }
        } catch (err) { 
            setCarregandoTopVendedores(false);
            alert(`Erro ao carregar Top 3 vendedores, verifique a conexão com a internet e tente novamente. -> ${String(err)}`);
        }
    }

    async function CarregarSaldoCaixa() {
        try {
            if (Login.user != null) {
                setCarregandoSaldoCaixa(true);
                await apiTuig.get(`saldoCaixa?acessKey=${Login.client}&user=${Login.user}&pass=${Login.pass}&data=${Period.dayFrom.toLocaleDateString('pt-BR', { timeZone: 'America/Recife' })}`).then(response => {
                    setSaldoCaixa(response.data);
                    setCarregandoSaldoCaixa(false);
                    //Se senha ou autorizacao mudar no servidor, desloga usuario
                    if (response.data.error !== '') {
                        alert(response.data.error);
                        return (
                            <div>
                                {GoToLogin()};
                            </div>
                        )
                    }
                    })
                }
        } catch (err) {
            setCarregandoSaldoCaixa(false);
            alert(`Erro ao carregar Saldo do caixa, verifique a conexão com a internet e tente novamente. -> ${String(err)}`);
        }
    }

    async function CarregarRetiradasCaixa() {
        try {
            if (Login.user != null) {
                setCarregandoRetiradasCaixa(true);
                await apiTuig.get(`retiradasCaixa?acessKey=${Login.client}&user=${Login.user}&pass=${Login.pass}&data=${Period.dayFrom.toLocaleDateString('pt-BR', { timeZone: 'America/Recife' })}`).then(response => {
                    setRetiradasCaixa(response.data);
                    setCarregandoRetiradasCaixa(false);
                    //Se senha ou autorizacao mudar no servidor, desloga usuario
                    if (response.data.error !== '') {
                        alert(response.data.error);
                        return (
                            <div>
                                {GoToLogin()};
                            </div>
                        )
                    }
                    })
                }
        } catch (err) {
            setCarregandoRetiradasCaixa(false);
            alert(`Erro ao carregar Retiradas do caixa, verifique a conexão com a internet e tente novamente. -> ${String(err)}`);
        }
    }

    useEffect(() => {
        const momentObj = moment(startDate, 'DD/MM/YYYY');    
        Period.dayFrom = momentObj.toDate();
        sessionStorage.setItem('@tuigcloud/dayFrom', Period.dayFrom.toLocaleDateString('pt-BR', { timeZone: 'America/Recife' }));

        CarregarUltAtualizacao();
        CarregarVendas();
        CarregarTopProdutos();
        CarregarTopVendedores();
        CarregarSaldoCaixa();
        CarregarRetiradasCaixa();

    }, [startDate]);

    function handleChangeDate() {        
        return;
    }

    function HandleClickButtonMais() {
        const momentObj = moment(Period.dayFrom, 'DD/MM/YYYY');
        momentObj.add(1,'days');
        sessionStorage.setItem('@tuigcloud/dayFrom', momentObj.format('DD/MM/YYYY').toLocaleString());
        Period.dayFrom = momentObj.toDate();
        setStartDate(momentObj.format('DD/MM/YYYY').toLocaleString());
        return;        
    }
    
    function HandleClickButtonMenos() {
        const momentObj = moment(Period.dayFrom, 'DD/MM/YYYY');
        momentObj.add(-1,'days');
        sessionStorage.setItem('@tuigcloud/dayFrom', momentObj.format('DD/MM/YYYY').toLocaleString());
        Period.dayFrom = momentObj.toDate();
        setStartDate(momentObj.format('DD/MM/YYYY').toLocaleString());
        return;
    }

    function GoToLogin() {
        history.push('/');
    }

    function LoadHome() {
        let momentObj = moment(new Date(), 'DD/MM/YYYY'); 
        const sessionDayfrom = sessionStorage.getItem('@tuigcloud/dayFrom');
        
        if (sessionDayfrom) {
            momentObj = moment(sessionDayfrom, 'DD/MM/YYYY');
            Period.dayFrom = momentObj.toDate();
        } 
        Login.isAutenticated = sessionStorage.getItem('@tuigcloud/isAutenticated');
        Login.user = sessionStorage.getItem('@tuigcloud/user');
        Login.pass = sessionStorage.getItem('@tuigcloud/pass');
        Login.client = localStorage.getItem('@tuigcloud/client');
        Login.clientName = sessionStorage.getItem('@tuigcloud/clientName');
        
        if (Login.isAutenticated === 'true') {
            return (
                <div id="home">
                    <div className="content">
                        <Header />
                        <br/>
                        <br/>
                        <p>Sua dashboard em tempo real</p>
                        <p>Atualizado em {ultimaAtualizacao.ultAtualizacao}</p>
                        <p>{ultimaAtualizacao.regPendentes} registros a sincronizar</p>
                        <br/>
                        <h3>{Login.clientName}</h3>
                        <main className="content">
                            <div id="barraData">
                            <div id="barraDataInterior">
                            <button id="botaoMaisMenos" type="button" onClick={HandleClickButtonMenos}>-</button>
                            {/* esse date &&  usado no onCahnge quer dizer que a funcao so vai ser invogada quando Date não for null
                            
                                The onChange callback from react-datepicker looks like this:

                                onChange(date: Date | null, event: React.SyntheticEvent<any> | undefined): void;
                                So date potentially can be null. You have two options here:

                                1.) accept nullable date state in useState Hook:

                                const [startDate, setStartDate] = useState<Date | null>(new Date());
                                2.) only invoke setStartDate, when date is not null:

                                <DatePicker selected={startDate} onChange={date => date && setStartDate(date)} />*/}
                                {/*Se for mobile ativa logo o readonly no primeiro toque sem abrir teclado*/}
                                {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) 
                                 ? <DatePicker id="date" 
                                               onSelect={date => date && setStartDate(date.toLocaleString('pt-BR', { timeZone: 'America/Recife' }))}
                                               selected={Period.dayFrom} 
                                               onChange={date => date && setStartDate(date.toLocaleString('pt-BR', { timeZone: 'America/Recife' }))} 
                                               dateFormat="dd/MM/yyyy" 
                                               todayButton="Ir para hoje" 
                                               locale="ptBR" 
                                               onFocus={(e) => e.target.readOnly = true}
                                               maxDate={new Date()}/>
                                 : <DatePicker id="date" 
                                               onSelect={date => date && setStartDate(date.toLocaleString('pt-BR', { timeZone: 'America/Recife' }))}
                                               selected={Period.dayFrom} 
                                               onChange={date => date && setStartDate(date.toLocaleString('pt-BR', { timeZone: 'America/Recife' }))} 
                                               dateFormat="dd/MM/yyyy" 
                                               todayButton="Ir para hoje" 
                                               locale="ptBR" 
                                               onFocus={(e) => e.target.readOnly = true}
                                               maxDate={new Date()}/> 
                                }
                            
                            <button id="botaoMaisMenos" type="button" onClick={HandleClickButtonMais}>+</button>
                            </div>
                            </div>
                            {/* <input id="date" type="date" value={selectedDay.substr(6,10) + '-' + selectedDay.substr(3,2) + '-' + selectedDay.substr(0,2)} onChange={handleChangeDate} /> */}
                            {/*<input id="date" type="date" value={Period.dayFrom} onChange={handleChangeDate}/>*/}
                            <div className="cards">
                                <ul>
                                    <li>
                                        <strong>{carregandoVendas ? "" : vendas.qtd} Vendas</strong>
                                        {carregandoVendas ? <Loading /> : <div>
                                                                    <div>{vendas.total}</div>                                        
                                                                    <div>Ticket Médio: {vendas.ticketMedio}</div>
                                                                    <div>{vendas.totalComparacao} em comparativo com o dia anterior</div>
                                                                    <br/>
                                                                    <div className="containerBotao"><Link className="botaoVerMais" to="/vendas">ver mais</Link></div>
                                                                    </div>}
                                        {/*<div>
                                            <button onClick={() => alert('opa')}>Ver mais</button>
                                        </div>*/}
                                    </li> 
                                    <li>
                                        <strong>Top 3 Produtos mais vendidos</strong>
                                        {carregandoTopProdutos ? <Loading /> : <div>
                                                                            {topProdutos.map(produto => (
                                                                            <p key={produto}>
                                                                                {produto}
                                                                            </p>
                                                                            ))}     
                                                                            <br/>
                                                                            <div className="containerBotao"><Link className="botaoVerMais" to="/produtosVendidos">ver mais</Link></div>
                                                                            </div>}
                                    </li> 
                                    <li>
                                        <strong>Top 3 Vendedores</strong>
                                        {carregandoTopVendedores ? <Loading /> : <div>
                                                                            {topVendedores.map(vendedor => (
                                                                            <p key={vendedor}>
                                                                                {vendedor}
                                                                            </p>
                                                                            ))}     
                                                                            <br/>
                                                                            <div className="containerBotao"><Link className="botaoVerMais" to="/vendasVendedor">ver mais</Link></div>
                                                                            </div>}
                                    </li> 
                                    <li>
                                        <strong>Saldo geral do caixa</strong>
                                        {carregandoSaldoCaixa ? <Loading /> : <div>
                                                                            <div>{saldoCaixa.valor}</div>    
                                                                            <br/>
                                                                            <div className="containerBotao"><Link className="botaoVerMais" to="/saldoCaixa">ver mais</Link></div>
                                                                            </div>}
                                    </li>
                                    <li>
                                        <strong>{retiradasCaixa.retiradas} Retiradas de caixa</strong>
                                        {carregandoRetiradasCaixa ? <Loading /> : <div>
                                                                                  <div>{retiradasCaixa.valor}</div>    
                                                                                  <br/>
                                                                                  <div className="containerBotao"><Link className="botaoVerMais" to="/retiradasCaixa">ver mais</Link></div>
                                                                                  </div>}
                                    </li>
                                    {/*<li>
                                        <strong>Curva ABC</strong>
                                        <div>-- Função estará disponível até o final do dia --</div>
                                        {/*
                                        <div>Classe A (80%) - 22 produtos</div>
                                        <div>Classe B (15%) - 125 produtos</div>
                                        <div>Classe C (5%)- 629 produtos</div>
                                        <br/>
                                        <Button id="botaoVerMais" text="ver mais"/>
                                        */}
                                    {/*</li>
                                    <li>
                                        <strong>Final de estoque</strong>
                                        <div>-- Função estará disponível até o final do dia --</div>
                                        {/*
                                        <strong>12 produtos</strong> em final de estoque
                                        <br/>
                                        <strong>5 ajustes</strong> de estoque realizados
                                        <br/>
                                        <strong>2 perdas</strong> de estoque registradas
                                        <br/>
                                        <br/>
                                        <Button id="botaoVerMais" text="ver mais"/>
                                        */}
                                    {/*</li>
                                    <li>
                                        <strong>Atraso na marcação do ponto</strong>
                                        <div>-- Função estará disponível até o final do dia --</div>
                                        {/*
                                        <div>2 colaboradores</div>
                                        <br/>
                                        <div>João francisco - 12 min - 08:12:21</div>
                                        <div>Antônio - 3 min - 08:03:11</div>
                                        <br/>
                                        <Button id="botaoVerMais" text="ver mais"/>
                                        */}
                                    {/*</li>*/}
                                </ul>
                            </div>                    
                        </main>

                        <br/>
                        <br/>
                        <br/>
                        <div>
                            <a href="https://apps.inovesistemas.info/changelog/sistemas/Tuig"><img id="bannerChanges" src={bannerChanges} alt=""/></a>
                        </div>
                        <div>
                            <a href="https://loja.inovesistemas.info"><img id="bannerLoja" src={bannerLoja} alt=""/></a>
                        </div>
                        <div>
                            <a href="https://inovesistemas.info/indicacao/?ind=1"><img id="bannerBonif" src={bannerBonif} alt=""/></a>
                        </div>

                        <Footer />
                    </div> 
                </div>
            )
        }
        else return (
            <div>
                {GoToLogin()};
            </div>
        )
    }
    return (
        <div>{LoadHome()}</div>
    );
};

export default Dashboard;