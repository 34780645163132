import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';

import './styles.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Loading from '../../components/Loading/Loading';

import PeriodContext from '../../Contexts/PeriodContext';
import apiTuig from '../../services/apiTuig';

import { UltimaAtualizacao, VendasGeral, AcessConfig, DetalheFormaPag } from '../../Types/Types';
import LoginContext from '../../Contexts/LoginContext';

import { useHistory } from 'react-router-dom';

interface TopProdutosResponse {
    produto: string,
    error: string;
}

const ProdutosVendidos = () => {

        const Period = useContext(PeriodContext);
        const Login = useContext(LoginContext);

        const history = useHistory();

        const [topProdutos, setTopProdutos] = useState<string[]>([]);
        const [carregandoTopProdutos,setCarregandoTopProdutos] = useState(true);
        const [ultimaAtualizacao, setUltimaAtualizacao] = useState<UltimaAtualizacao>({
            ultAtualizacao: '',
            regPendentes: '',
            error: ''
        });

        useEffect(() => {
            LoadHome();       
        },[]); 

        async function CarregarUltAtualizacao() {
            try {
                if (Login.user != null) {
                    await apiTuig.get(`getUltAtualizacao?acessKey=${Login.client}&user=${Login.user}&pass=${Login.pass}`).then(response => {
                        setUltimaAtualizacao(response.data);
                        //Se senha ou autorizacao mudar no servidor, desloga usuario
                        if (response.data.error !== '') {
                            alert(response.data.error);
                            return (
                                <div>
                                    {GoToLogin()};
                                </div>
                            )
                        }
                        })
                    }
            } catch (err) {
                alert(`Erro ao carregar hora da última atualização, verifique a conexão com a internet e tente novamente. -> ${String(err)}`);
            }
        }

        async function CarregarTopProdutos() {
            try {
                if (Login.user != null) {
                    setCarregandoTopProdutos(true);
                    await apiTuig.get<TopProdutosResponse[]>(`topprodutos?acessKey=${Login.client}&user=${Login.user}&pass=${Login.pass}&data=${Period.dayFrom.toLocaleDateString('pt-BR', { timeZone: 'America/Recife' })}&qtdReg=0`).then(response => {
                        const produto = response.data.map(produto => produto.produto);
                        setTopProdutos(produto);
                        setCarregandoTopProdutos(false);   
                    })
                }
            } catch (err) {
                setCarregandoTopProdutos(false);
                alert(`Erro ao carregar Top 3 produtos mais vendidos, verifique a conexão com a internet e tente novamente. -> ${String(err)}`);
            }
        }

        useEffect(() => {
            CarregarUltAtualizacao();
            CarregarTopProdutos();
        },[]);

        function GoToLogin() {
            history.push('/');
        }
    
        function LoadHome() {
            if (sessionStorage.getItem('@tuigcloud/dayFrom') != null) {
                const momentObj = moment(sessionStorage.getItem('@tuigcloud/dayFrom'), 'DD/MM/YYYY');
                Period.dayFrom = momentObj.toDate();
            }
            Login.isAutenticated = sessionStorage.getItem('@tuigcloud/isAutenticated');
            Login.user = sessionStorage.getItem('@tuigcloud/user');
            Login.pass = sessionStorage.getItem('@tuigcloud/pass');
            Login.client = localStorage.getItem('@tuigcloud/client');
            Login.clientName = sessionStorage.getItem('@tuigcloud/clientName');
            
            if (Login.isAutenticated === 'true') {
                return (
                        <div id="home">
                    <div className="content">
                        <Header />
                        <br/>
                        <br/>
                        <p>Atualizado em {ultimaAtualizacao.ultAtualizacao}</p>
                        <p>{ultimaAtualizacao.regPendentes} registros a sincronizar</p>
                        <br/>
                        <h3>{Login.clientName}</h3>
                        <h1>Produtos mais vendidos {Period.dayFrom.toLocaleDateString('pt-BR', { timeZone: 'America/Recife' })}</h1>
                        <br/>
                        
                        {carregandoTopProdutos ? <Loading /> : <div id="container">
                                                               {topProdutos.map(produto => (
                                                               <p key={produto}>
                                                                   {produto}
                                                               <hr/>
                                                               </p>
                                                               
                                                               ))}     
                                                               <br/>
                                                               </div>}
                        <Footer />
                        
                    </div>
                </div>
                )
            }
            else {
                return (
                    <div>
                        {GoToLogin()};
                    </div>
                )
            }
        }
    return (
        <div>{LoadHome()}</div>
    );
};

export default ProdutosVendidos;
