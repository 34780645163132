import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Routes from './routes';

function App() {
    return ( 
      <Routes />
    );
}

export default App;
